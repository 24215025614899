import React from "react"
import Header from "../../components/header"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import Footer from "../../components/footer"
import ContactBox from "../../components/boxContact"

import imgFlachdach01 from "../../assets/leistungen/flachdach01.jpg"
import imgFlachdach02 from "../../assets/leistungen/flachdach02.jpg"
import imgFlachdach03 from "../../assets/leistungen/flachdach03.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung
      Title="Bedachungen Flachdach"
      Content="Flachdächer kämpfen mit dem Vorurteil, undicht zu sein und einen&nbsp; 
        höheren Wartungsaufwand zu verursachen. Zu Unrecht. Wichtig dabei&nbsp; 
        sind die fachgerechte Planung, die Verwendung hochwertiger&nbsp; 
        Materialien sowie die professionelle Verarbeitung. Mit unserem&nbsp; 
        Know how sorgen wir für eine hohe Lebenserwartung Ihres&nbsp; 
        Flachdachs."
    />
    <LeistungBox
      Color="gray"
      Image={imgFlachdach01}
      IsImageLeft={true}
      IsFadeLeft={true}
      Title="Bitumen-Schweißbahnen"
      Content="Die Außenhaut eines Daches ist täglich großen Belastungen&nbsp; 
        ausgesetzt. Sie muss Wind und Wetter standhalten. Sie soll&nbsp; 
        Hagelkörnern trotzen, extreme Hitze im Sommer aushalten und&nbsp; 
        klirrende Kälte im Winter ertragen. Hochwertige&nbsp; 
        Elastomer-Bitumenbahnen schützen Ihr Gebäude über Jahrzehnte&nbsp; 
        hinweg. Dieser Baustoff ist wärmebeständig bis zu 120°C und&nbsp; 
        erträgt Minustemperaturen bis zu minus 35°C. In der Regel werden&nbsp; 
        die Bahnen mithilfe eines Flammbrenners aufgeschweißt. Es gibt&nbsp; 
        aber auch sogenannte Kaltselbstklebebahnen, die ohne Flamme&nbsp; 
        aufgebracht werden können."
    />
    <LeistungBox
      Color="white"
      Image={imgFlachdach02}
      IsImageLeft={false}
      IsFadeLeft={false}
      Title="Kunststoff-Dachbahnen"
      Content="Für die moderne Wohnhausarchitektur mit ihren flachen&nbsp; 
        Dachkonstruktionen kommen Dachbahnen aus Kunststoff immer&nbsp; 
        häufiger zum Einsatz. Verlegt werden die Dachbahnen entweder&nbsp; 
        lose mit Auflast durch Kies, Gründachaufbauten oder durch&nbsp; 
        Terrassenbeläge. Eine andere Möglichkeit ist die mechanische&nbsp; 
        Befestigung im Untergrund. Die vielen positiven Eigenschaften&nbsp; 
        kommen jedoch nur bei hochwertigen Kunststoffbahnen zum Tragen –&nbsp; 
        und auch hier ist die fachgerechte Ausführung das A und O."
    />
    <LeistungBox
      Color="gray"
      Image={imgFlachdach03}
      IsImageLeft={true}
      Title="Flüssigabdichtung"
      Content="Filigrane Anschlüsse, knifflige Details und schmale Fugen sind die&nbsp; 
        klassischen Schwachstellen bei Dachkonstruktionen. Für die&nbsp; 
        speziellen Anforderungen dieser Bereiche gibt es die Abdichtung&nbsp; 
        mit Flüssigkunststoffen."
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
